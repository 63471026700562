import './scss/index.scss';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

//toast
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

//pages
import MainLayout from './components/layout/main-layout';
import Forum from './pages/Forum';
import Store from './pages/Store';
import Car from './pages/Car';
import Home from './pages/Home';
import Profile from './pages/Profile';
import TermsAndConditions from './pages/T&C';

//css
import './scss/component/_one-signal.scss';

//utility
import { initializeOneSignal, observeNotificationChanges } from './utility/one_signal/OneSignalSetup';

//lib
import "swiper/css/bundle";
import MainNavBar from './components/navbar/main-navbar';
import useWindowDimensions from './components/common/window-dimension';
import Product from './pages/Store/components/product';
import Cart from './pages/Store/components/cart';
import Checkout from './pages/Store/components/checkout';
import { useEffect, useState } from 'react';

function App() {
  const { width } = useWindowDimensions();

  const [initialized, setInitialized] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState("default");

  useEffect(() => {
    initializeOneSignal(setInitialized, setNotificationPermission);
  }, []);

  useEffect(() => {
    observeNotificationChanges(notificationPermission);
  }, [notificationPermission]);

  return (
    <BrowserRouter>
    <Routes>
      <Route 
        path="/"
        element={
          <MainLayout>
            <Home />
          </MainLayout>
        } 
      />
      <Route 
        path="/:state"
        element={
          <MainLayout>
            <Home />
          </MainLayout>
        } 
      />
      <Route 
        path="/forum"
        element={
          <>
            <main className="content-container"><Forum /></main>
            {width <= 991 && <MainNavBar />}
          </>
        } 
      />
      <Route 
        path="/store" 
        element={
          <MainLayout>
            <Store />
          </MainLayout>
        } 
      />
      <Route 
        path="/cart" 
        element={
          <MainLayout>
            <Cart />
          </MainLayout>
        } 
      />
      <Route 
        path="/checkout" 
        element={
          <MainLayout>
            <Checkout />
          </MainLayout>
        } 
      />
      <Route 
        path="/product/:id" 
        element={
          <Product />
        } 
      />
      <Route 
        path="/product/:id/:state" 
        element={
          <Product />
        } 
      />
      <Route 
        path="/car" 
        element={
          <MainLayout>
            <Car />
          </MainLayout>
        } 
      />
      <Route 
        path="/car/:id/:state" 
        element={
          <MainLayout>
            <Car />
          </MainLayout>
        } 
      />
      <Route 
        path="/profile" 
        element={
          <MainLayout>
            <Profile />
          </MainLayout>
        } 
      />
      <Route 
        path="/profile/:state" 
        element={
          <MainLayout>
            <Profile />
          </MainLayout>
        } 
      />
       <Route 
        path="/terms-of-use" 
        element={
          <MainLayout>
            <TermsAndConditions />
          </MainLayout>
        } 
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
    <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
