import OneSignal from 'react-onesignal';

//reun-user
export const initializeOneSignal = (setInitialized, setNotificationPermission) => {
    OneSignal.init({ 
      appId: "88e23579-7f83-47d6-ae58-082fee2ec436", 
      autoRegister: false,
      allowLocalhostAsSecureOrigin: true,
      notifyButton: {
        enable: true,
        size: 'medium',
        position: 'bottom-left',
        theme: 'default',
        prenotify: true,
        showCredit: false,
        text: {
        'tip.state.unsubscribed': 'Stay up-to-date with important updates.',
        'tip.state.subscribed': `You're going to receive timely updates.`,
        'tip.state.blocked': 'Access notification settings to enable updates.',
        'message.prenotify': 'Click to allow notification.',
        'message.action.subscribed': 'Notifications setting updated!',
        'message.action.resubscribed': 'Notifications re-enabled successfully!',
        'message.action.unsubscribed': `You won't receive notifications anymore.`,
        'dialog.main.title': 'Manage Notifications',
        'dialog.main.button.subscribe': 'Notify Me',
        'dialog.main.button.unsubscribe': `Don't Notify Me`,
        'dialog.blocked.title': `Unblock Notifications`,
        'dialog.blocked.message': 'Follow these instructions to allow notifications:'
        },
        colors: {
        'circle.background': '#212a39', 
        'circle.foreground': '#ffffff',
        'badge.background': '#ff0000',
        'badge.foreground': '#ffffff',
        'badge.bordercolor': '#ff0000',
        'pulse.color': '#3db8ed',
        }
      },
      welcomeNotification: {
          title: 'Welcome to Reunion Motor Sports!',
          message: 'Stay tuned for the latest updates.',
      }
    }).then(() => {
      setInitialized(true)
      const isUserPushSubscription = OneSignal.User && OneSignal.User.PushSubscription;

      const handleSubscriptionChange = async () => {
        if (isUserPushSubscription) {
          const { optedIn, id: subcriptionId} = OneSignal.User.PushSubscription;
          const newPermission = optedIn ? "granted" : "denied";

          setNotificationPermission(newPermission);
        }
      };
  
      if (isUserPushSubscription) {
        OneSignal.User.PushSubscription.addEventListener('change', handleSubscriptionChange);
      }

      return () => {
        if (isUserPushSubscription) {
          OneSignal.User.PushSubscription.removeEventListener('change', handleSubscriptionChange);
        }
      };
    });
  };
  
export const observeNotificationChanges = (notificationPermission) => {
  if (notificationPermission === 'denied') {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
      if (mutation.addedNodes && mutation.addedNodes.length > 0) {
        const instructionElements = document.querySelectorAll('.instructions');

        instructionElements.forEach(element => {
          const anchorTag = element.querySelector('a');
          if (anchorTag) {
            anchorTag.href = 'https://i.ibb.co/fN07nTn/reun-user-instruction.jpg';
          }
      
          const imgTag = element.querySelector('img');
          if (imgTag) {
            imgTag.src = 'https://i.ibb.co/fN07nTn/reun-user-instruction.jpg';
          }
        });
      }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });
    return () => observer.disconnect();
  }
};